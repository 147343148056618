
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButtons, IonButton, menuController, modalController } from "@ionic/vue";
import { refresh, menu } from "ionicons/icons";

import { computed, onMounted, ref } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import { Calendar } from "v-calendar";

import { dateFormat } from "@/services/utils";
import { openToast } from "@/services/toast";
import apiTasks from "@/services/tasks";

import TaskDetail from "@/components/task/TaskDetail.vue";

export default {
    name: "Tickets",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButtons,
        IonButton,
        Calendar,
    },
    setup() {
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id;

        const loading = ref(false);

        const date = ref(new Date());
        const selectedDay = ref(null); // Add state to store selected day

        const tasksToShow = ref([]);

        const tasks = ref([]);
        async function loadTasks() {
            loading.value = true;
            try {
                const res = await apiTasks.getTasks(userID);
                //console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    tasks.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta delle tasks", "toast_danger");
                }
            } catch (error) {
                console.error(error);
                openToast("Si è verificato un errore durante la richiesta delle tasks", "toast_danger");
            } finally {
                loading.value = false;
            }
        }

        const setAttributes = computed(() => {
            return tasks.value.map((task) => ({
                key: `task.${task.tasks_id}`,
                highlight: {
                    color: "blue",
                    fillMode: "solid",
                },
                dates: task.tasks_due_date,
                customData: task,
            }));
        });

        /**
         * Filter my tickets based on selected day
         */
        let selectedDate = null;
        const dayClicked = (day) => {
            selectedDate = day.id;
            selectedDay.value = day;
            const selectedDayTasks = day.attributes;

            if (selectedDayTasks) {
                //svuoto e popolo con ticket della giornata selezionata
                tasksToShow.value = [];
                selectedDayTasks.forEach((element) => {
                    tasksToShow.value.push(element.customData);
                });
            } else {
                tasksToShow.value = [];
            }
            //tasksToShow.value = [...selectedDayTasks];
        };

        /**
         * Open sidebar menu
         */
        const openMenu = () => {
            menuController.open("app-menu");
        };

        async function openDetail(task) {
            //console.log(richiestaDetail);
            const modal = await modalController.create({
                component: TaskDetail,
                componentProps: {
                    data: task,
                },
            });
            modal.onDidDismiss().then((detail) => {
                /* if (detail.data != undefined) {
          richieste.value = [detail.data, ...richieste.value];
          openToast("Nuova richiesta inserita", "toast_success");
        } */
            });
            return modal.present();
        }

        //Set correct background for task priority
        const taskPriority = computed(() => {
            return (statusId) => {
                let className = "";
                if (statusId == 1) {
                    //task aperti
                    className = "badge_low";
                } else if (statusId == 2) {
                    //task in lavorazione
                    className = "badge_medium";
                } else if (statusId == 3) {
                    //task attesa risposta
                    className = "badge_high";
                }
                return className;
            };
        });

        onMounted(() => {
            loadTasks();
        });

        return {
            loading,
            dateFormat,
            refresh,
            openDetail,
            openMenu,
            menu,
            //Calendar
            date,
            selectedDay,
            setAttributes,
            dayClicked,
            //filter tasks based on calendr click
            tasksToShow,
            //TASK
            tasks,
            loadTasks,
            taskPriority,
        };
    },
};
